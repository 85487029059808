import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfContent from "@/vue/domain/content/df-content";
import DfProperty from "@/vue/domain/content/df-property";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Carousel, Slide } from "vue-carousel-variable-width";
import DfStore from "@/vue/domain/store/df-store";
import DfBrand from "@/vue/domain/brand/df-brand";

@Component({
  components: {
    Carousel,
    Slide,
  },
})
export default class DfSectionBannerComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({
    type: Array,
    default: () => {
      [];
    },
  })
  banners!: Array<DfContent>;

  @Prop({ type: String, default: "" })
  position!: string;
  @Prop({ type: Boolean, default: false })
  carouselView!: boolean;
  @Prop({ type: Boolean, default: false })
  fullWidth!: boolean;
  @Prop({ type: Boolean, default: true })
  columnDirection!: boolean;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get carouselAutoplay(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_CAROUSEL_AUTOPLAY, "BOOLEAN");
  }

  get bannersByPosition(): Array<DfContent> {
    return this.banners.filter((banner: DfContent) => {
      // TODO: Eliminare a inizio 2023
      const valueString: string = Utils.getPropertyValue(banner, Utils.PROPERTY_POSITION, "STRING");
      const valueList: any = Utils.getPropertyValue(banner, Utils.PROPERTY_POSITION, "LIST", {});

      return valueString === this.position || valueList.key === "ALL" || valueList.key === this.position;
    });
  }

  get bannersPreviewUrl(): Array<string> {
    let bannersPreviewUrl: Array<string> = [];
    this.bannersByPosition.forEach((banner: DfContent) => {
      const property: DfProperty = banner.properties ? banner.properties.find((property: DfProperty) => property.isFile) || null : null;
      bannersPreviewUrl = property && property.values.length > 0 ? [...bannersPreviewUrl, `${Utils.getEndpointContextPath()}/files/${property.values[0].uniqueId}/${property.values[0].basename}.${property.values[0].extension}`] : bannersPreviewUrl;
    });

    return bannersPreviewUrl;
  }

  get bannersLinkUrl(): Array<string> {
    let bannersLinkUrl: Array<string> = [];
    this.bannersByPosition.forEach((banner: DfContent) => {
      const property: DfProperty = banner.properties ? banner.properties.find((property: DfProperty) => property.code === Utils.PROPERTY_LINK) || null : null;
      bannersLinkUrl = property && property.values.length > 0 ? [...bannersLinkUrl, property.values[0]] : [...bannersLinkUrl, null];
    });

    return bannersLinkUrl;
  }

  @Watch("bannersPreviewUrl", { immediate: true })
  private changeVisibility() {
    this.$emit("setVisibility", this.bannersPreviewUrl.length > 0);
  }

  private onClick(bannersLinkUrl: string) {
    if (bannersLinkUrl) {
      Utils.isExternalLink(bannersLinkUrl) ? window.open(bannersLinkUrl, "_blank") : this.$router.push({ path: bannersLinkUrl.startsWith("/") ? bannersLinkUrl : `/${bannersLinkUrl}` });
    }
  }
}
